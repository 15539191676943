import React, { useCallback, useMemo, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Alert, Button, Form, Select, Spin, notification } from "antd";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";
import { DatePicker } from "../../../components/index.js";

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

export const BlankReportPage = () => {

  const [date, setDate] = useState<Date | null>(null);
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [status, setStatus] = useState(['active']);
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [hasDownloadFailed, setDownloadFailed] = useState(false);

  // only including the timezones that are relevant to our business, plus UTC
  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'deleted', label: 'Deleted' }
  ];

  const parameters = useMemo(() => {
    const paramDictionary: Record<string, Array<string>> = {
      'status': status,
      'timezone': [timezone]
    }

    if (date) {
      const dateString = DateTime.fromJSDate(date).setZone(timezone, { keepLocalTime: true }).startOf('day').toISO();
      if (dateString) {
        paramDictionary['start_date'] = [dateString]
      }
    }

    const paramArray = Object.entries(paramDictionary).flatMap(([key, value]) => {
      const keyString = (value?.length || 0) > 1 ? `${key}[]` : key;
      return value?.map(v => `${keyString}=${v}`)
    });

    return paramArray.join('&');
  }, [status, date, timezone]);

  const fileUrl = useMemo(() => `${CORE_BASE_URL}/blanks/report?${parameters}`, [parameters]);

  // easier to generate file name here than alter CORS on server and parse filename from content disposition
  const fileName = useMemo(
    () => `${status.sort().join('_')}_blanks_${DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ')}.csv`,
    [status, timezone]);

  // shenanigans to make the dowload detectable within the browser
  const download = useCallback(() => {
    setDownloadDisabled(true);
    setDownloadLoading(true);
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
      .then(async (response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        setDownloadFailed(true);
        notification.open({
          key: "error",
          message: "Report Error",
          description: "Something went wrong while generating your report. Please try the manual download link.",
          duration: 0,
        });
      });
  }, [fileUrl, fileName]);

  const onClickManualDownload = useCallback(() => {
    setDownloadFailed(false);
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadDisabled(false);
      setDownloadLoading(false);
    }, 5000);
  }, []);

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Blanks Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={setTimezone}
          />
        </Form.Item>
        <Form.Item
          label="Statuses"
          extra="Generate a report for selected statuses."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={statusOptions}
            defaultValue={status}
            onChange={setStatus}
            mode='multiple'
          />
        </Form.Item>
        <Form.Item
          label="Start Date"
          extra="Generate a report between this date and now. Leave blank to include all records."
          style={{ marginBottom: 10 }}
        >
          <DatePicker value={date} onChange={setDate} allowClear={true} />
        </Form.Item>
        { status.length == 0 && <Alert key="alert" type="error" message="At least one status must be selected." style={{ marginBottom: 10 }}/> }
        <Form.Item>
          <Button type="primary" onClick={download} disabled={status.length == 0}>Download</Button>
        </Form.Item>
        { isDownloadLoading &&
          (
            <Spin tip="Preparing Report..." size="large">
              <div className="content" />
            </Spin>
          )
        }
        { hasDownloadFailed &&
          (
            <a href={fileUrl} download onClick={onClickManualDownload}>Manual Download</a>
          )
        }
      </Form>
    </ErrorBoundary>
  );
};
