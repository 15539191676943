import React, { ChangeEvent, useCallback, useMemo, useState, useRef } from "react";
import { Alert, Button, Card, Input, Space, Spin, Typography, Upload } from "antd";
import { AgGridReact } from "ag-grid-react";
import { ColDef, FirstDataRenderedEvent, GridOptions, ValueGetterParams } from "ag-grid-community";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Text } = Typography;

enum UPCStatus {
  Okay,
  WrongLength,
  NotANumber,
  Invalid
}

const UPCStatusMessage = {
  [UPCStatus.Okay]: 'UPC is valid',
  [UPCStatus.WrongLength]: 'UPC is not 12 characters in length',
  [UPCStatus.NotANumber]: 'UPC is not a number',
  [UPCStatus.Invalid]: 'UPC check-digit validation failed'
};

interface ProductVariant {
  art: string;
  blank: string;
  item: string;
  status: string;
  upc: string;
  weight: number;
  color: string;
}

interface UPCValidation {
  value: string;
  status: UPCStatus;
}

// Regex for cleaning input - compile once outside component
const REGEX_SPACE = /[\s,;'"]+/g;
const BATCH_SIZE = 10;

export const ProductVariantPage = () => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [complete, setComplete] = useState(false);
  const [rowData, setRowData] = useState<ProductVariant[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  
  // Store unique values in ref to avoid re-renders
  const uniqueValuesRef = useRef(new Set<string>());

  const processText = useCallback((text: string) => {
    const spaceFixedInput = text.replace(REGEX_SPACE, ' ');
    const values = spaceFixedInput.split(' ');
    const uniqueValues = new Set(values.filter(id => id.length > 0));
    uniqueValuesRef.current = uniqueValues;
  }, []);

  // Process any input value changes, whether from typing or programmatic updates
  const processValue = useCallback((value: string) => {
    setInput(value);
    processText(value);
  }, [processText]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    processValue(e.target.value);
  };

  const onFileChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsProcessing(true);
      return;
    }
    
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        processValue(text);
        setIsProcessing(false);
      };
      reader.readAsText(info.file.originFileObj);
    }
  };

  const uniqueValues = useMemo(() => {
    return Array.from(uniqueValuesRef.current);
  }, [input]);

  const validateUPC = (upc: string) => {
    if (upc.length !== 12) {
      return UPCStatus.WrongLength;
    }

    const checkDigit = parseInt(upc[11], 10);
    const aDigits = parseInt(upc[0], 10) + parseInt(upc[2], 10) + parseInt(upc[4], 10) + parseInt(upc[6], 10) + parseInt(upc[8], 10)
      + parseInt(upc[10], 10);
    const bDigits = parseInt(upc[1], 10) + parseInt(upc[3], 10) + parseInt(upc[5], 10) + parseInt(upc[7], 10) + parseInt(upc[9], 10);

    if (Number.isNaN(aDigits) || Number.isNaN(bDigits) || Number.isNaN(checkDigit)) {
      return UPCStatus.NotANumber;
    }

    const total = aDigits * 3 + bDigits;
    let check = total % 10;
    if (check !== 0) {
      check = 10 - check;
    }
    if (check !== checkDigit) {
      return UPCStatus.Invalid;
    }

    return UPCStatus.Okay;
  };

  const validationValueGetter = (params: ValueGetterParams<UPCValidation>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    const value = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    return UPCStatusMessage[value as UPCStatus];
  };

  const errorRowData: UPCValidation[] = useMemo(() => {
    return uniqueValues.map((uv) => ({
      status: validateUPC(uv),
      value: uv
    })).filter((p) => p.status !== UPCStatus.Okay);
  }, [uniqueValues]);

  const isDisabled = useMemo(() => uniqueValues.length === 0 || errorRowData.length !== 0, [uniqueValues, errorRowData]);

  const processBatch = async (batch: string[]) => {
    const response = await fetch(`${CORE_BASE_URL}/d365/product_variants`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ item_bar_code: batch })
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json() as ProductVariant[];
  };

  const onFinish = async () => {
    setLoading(true);
    setRowData([]);
    setError('');
    setComplete(false);
    setProgress(0);

    try {
      // Split array into chunks of size BATCH_SIZE
      const batches = Array.from({ length: Math.ceil(uniqueValues.length / BATCH_SIZE) },
        (_, i) => uniqueValues.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE));
      let allResults: ProductVariant[] = [];

      for (let i = 0; i < batches.length; i++) {
        const batchResults = await processBatch(batches[i]);
        allResults = [...allResults, ...batchResults];
        setRowData(allResults);
        setProgress(Math.round(((i + 1) / batches.length) * 100));
      }
    } catch(e: unknown) {
      if (typeof e === "string") {
        setError(e);
      } else if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setLoading(false);
      setComplete(true);
    }
  };

  const defaultColDef = useMemo<ColDef>(() => ({
    sortable: true,
    enableCellChangeFlash: true,
    width: 120
  }), []);

  const columnDefs = useMemo<Array<ColDef<ProductVariant>>>(() => [
    {
      field: 'upc',
      headerName: 'UPC',
      sort: 'asc'
    },
    {
      field: 'item',
      headerName: 'Item'
    },
    {
      field: 'status',
      headerName: 'Status'
    },
    {
      field: 'weight',
      headerName: 'Weight'
    },
    {
      field: 'blank',
      headerName: 'Blank'
    },
    {
      field: 'color',
      headerName: 'Color'
    },
    {
      field: 'art',
      headerName: 'Art'
    }
  ], []);

  const errorColumnDefs = useMemo<Array<ColDef<UPCValidation>>>(() => [
    {
      field: 'value',
      headerName: 'UPC',
      sort: 'asc'
    },
    {
      field: 'status',
      headerName: 'Error',
      valueGetter: validationValueGetter
    }
  ], []);

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.txt,.csv',
    customRequest: ({ file, onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: onFileChange,
  };

  const gridOptions: GridOptions<ProductVariant> = useMemo(() => ({
    rowData,
    columnDefs,
    defaultColDef,
    animateRows: true,
    domLayout: 'autoHeight',
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [rowData, columnDefs, defaultColDef]);

  const errorGridOptions: GridOptions<UPCValidation> = useMemo(() => ({
    rowData: errorRowData,
    columnDefs: errorColumnDefs,
    defaultColDef,
    animateRows: true,
    domLayout: 'autoHeight',
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [errorRowData, errorColumnDefs, defaultColDef]);

  const loadingMessage = useMemo(() => {
    if (!loading) return '';
    return progress > 0 ? `Loading UPCs from D365 (${progress}%)` : 'Loading UPCs from D365';
  }, [loading, progress]);

  return (
    <>
      <div style={{ margin: 60 }}>
        <Spin tip={loadingMessage} spinning={loading}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            { error && <Alert type="error" showIcon message={error} description=" " /> }
            <Card title="Input UPCs">
              <ul>
                <li>Type or paste UPCs you wish to find in D365.</li>
                <li>UPCs may be either comma separated or one-per-line.</li>
                <li>Any invalid UPCs must be fixed before submission.</li>
                <li>You can also upload a CSV or text file containing UPCs.</li>
              </ul>
              <Space direction="vertical" size="small" style={{ width: '100%', marginBottom: 16 }}>
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Upload File</Button>
                </Upload>
                <Text type="secondary">Upload a CSV or text file containing UPCs (one per line or comma separated)</Text>
              </Space>
              <TextArea 
                value={input} 
                onChange={onChange} 
                rows={6}
                disabled={isProcessing}
              />
              <Text type="secondary">
                {isProcessing ? 'Processing...' : 
                  `${uniqueValues.length} Unique UPC${uniqueValues.length === 1 ? '' : 's'}`}
              </Text>
            </Card>
            {
              errorRowData.length > 0 &&
              (
                <Card title="Invalid UPCs">
                  <AgGridReact
                    className="ag-theme-madengine"
                    gridOptions={errorGridOptions}
                    rowData={errorRowData}
                  />
                  <Text type="secondary">{errorRowData.length} Error{errorRowData.length === 1 ? '' : 's'}</Text>
                </Card>
              )
            }
            <Button 
              type="primary" 
              disabled={isDisabled} 
              onClick={onFinish}
            >
              Submit
            </Button>
            {
              (complete && rowData.length !== uniqueValues.length) &&
                <Alert type="warning" showIcon message="Input and Result UPC counts do not match." description=" " />
            }
            {
              (complete || rowData.length > 0) &&
              (
                <Card title="Results">
                  <AgGridReact
                    className="ag-theme-madengine"
                    gridOptions={gridOptions}
                    rowData={rowData}
                  />
                  <Text type="secondary">{rowData.length} Result{rowData.length === 1 ? '' : 's'}</Text>
                </Card>
              )
            }
          </Space>
        </Spin>
      </div>
    </>
  );
};
