import React, { useCallback, useMemo, useState, useRef } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Button, Form, Select, Spin, Typography, Upload, Table, Empty, notification, Alert } from "antd";
import type { TableColumnsType } from "antd";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from "antd/es/upload/index.js";
import { SortOrder } from "antd/es/table/interface.js";

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

const { Text } = Typography;

interface TrackingData {
  tracking_code: string;
  transaction_date: string;
  postage: number;
  external_id?: string;
  transaction_type?: string;
}

interface ParsedRowData {
  key: string;
  pic: string;
  dateTime: string;
  formattedDateTime: string;
  postage: number;
  externalId?: string;
  transactionType?: string;
}

export const PostageFeeReportPage = () => {
  const [timezone, setTimezone] = useState<string>('America/Los_Angeles');
  const [isDownloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const [isDownloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [parsedData, setParsedData] = useState<ParsedRowData[]>([]);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Store parsed tracking codes and dates in ref to avoid re-renders
  const trackingDataRef = useRef<TrackingData[]>([]);

  const onFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      setIsProcessing(true);
      setFileUploaded(false);
      setError(null);
      return;
    }
    
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const text = e.target?.result as string;
        processCSV(text);
        setIsProcessing(false);
        setFileUploaded(true);
      };
      reader.readAsText(info.file.originFileObj as Blob);
    }
  };

  const processCSV = (csvText: string) => {
    // Split the CSV by lines
    const lines = csvText.split(/\r?\n/);
    if (lines.length === 0) {
      setError("The uploaded file appears to be empty.");
      setParsedData([]);
      trackingDataRef.current = [];
      return;
    }
    
    // Detect delimiter (tab or comma)
    const firstLine = lines[0];
    const delimiter = firstLine.includes('\t') ? '\t' : ',';
    
    // Split headers using the detected delimiter
    const headers = firstLine.split(delimiter);
    
    // Find the index of the columns we need
    const picIndex = headers.indexOf('Package Identification Code (PIC)');
    const dateTimeIndex = headers.indexOf('Transaction Date/Time');
    const postageIndex = headers.indexOf('Postage');
    const externalIdIndex = headers.indexOf('Cust Ref Num2');
    const transactionTypeIndex = headers.indexOf('Transaction Type');
    
    // Check for required columns
    const missingColumns: string[] = [];
    if (picIndex === -1) missingColumns.push('"Package Identification Code (PIC)"');
    if (dateTimeIndex === -1) missingColumns.push('"Transaction Date/Time"');
    if (postageIndex === -1) missingColumns.push('"Postage"');
    
    if (missingColumns.length > 0) {
      // Handle error: required columns not found
      const errorMessage = `Required columns not found in the uploaded file: ${missingColumns.join(', ')}.`;
      setError(errorMessage);
      
      notification.error({
        message: 'Invalid File Format',
        description: `The uploaded file is missing required columns: ${missingColumns.join(', ')}. Please make sure your file includes these columns and try again.`,
        duration: 0,
      });
      
      setParsedData([]);
      trackingDataRef.current = [];
      return;
    }

    // Parse the data rows
    const parsedRows: ParsedRowData[] = [];
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (!line) continue; // Skip empty lines
      
      // Split line using the same delimiter we used for headers
      const columns = line.split(delimiter);
      if (columns.length <= Math.max(picIndex, dateTimeIndex, postageIndex)) continue; // Skip malformed lines
      
      // Remove any quotes around the PIC
      const pic = columns[picIndex].replace(/['"]/g, '');
      const dateTime = columns[dateTimeIndex];
      const postageStr = columns[postageIndex].replace(/['"]/g, '');
      
      // Get external ID if available
      let externalId: string | undefined;
      if (externalIdIndex !== -1 && externalIdIndex < columns.length) {
        externalId = columns[externalIdIndex].replace(/['"]/g, '').trim();
        if (externalId === '') {
          externalId = undefined;
        }
      }
      
      // Get transaction type if available
      let transactionType: string | undefined;
      if (transactionTypeIndex !== -1 && transactionTypeIndex < columns.length) {
        transactionType = columns[transactionTypeIndex].replace(/['"]/g, '').trim();
        if (transactionType === '') {
          transactionType = undefined;
        }
      }
      
      // Convert postage to number, default to 0 if invalid
      const postage = parseFloat(postageStr) || 0;
      
      parsedRows.push({
        key: i.toString(),
        pic: pic,
        dateTime: dateTime,
        formattedDateTime: formatDateTime(dateTime, timezone),
        postage: postage,
        externalId: externalId,
        transactionType: transactionType
      });
    }
    
    // Sort by date/time (newest first)
    const sortedRows = [...parsedRows].sort((a, b) => {
      return new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime();
    });
    
    setParsedData(sortedRows);
    trackingDataRef.current = sortedRows.map(row => {
      const trackingData: TrackingData = {
        tracking_code: row.pic,
        transaction_date: row.dateTime,
        postage: row.postage
      };
      
      // Only add optional fields if they exist
      if (row.externalId) {
        trackingData.external_id = row.externalId;
      }
      
      if (row.transactionType) {
        trackingData.transaction_type = row.transactionType;
      }
      
      return trackingData;
    });
  };

  const formatDateTime = (dateTimeStr: string, timezone: string): string => {
    try {
      // Try different date formats (handle both "M/d/yyyy H:mm" and "yyyy-MM-dd HH:mm:ss" formats)
      let dt: DateTime;
      
      if (dateTimeStr.includes('-')) {
        // Format like "2025-03-06 18:00:04"
        dt = DateTime.fromFormat(dateTimeStr, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' });
      } else {
        // Format like "3/6/2025 18:00"
        dt = DateTime.fromFormat(dateTimeStr, 'M/d/yyyy H:mm', { zone: 'UTC' });
      }
      
      // Check if parsing was successful
      if (!dt.isValid) {
        return dateTimeStr; // Return original if parsing failed
      }
      
      return dt.setZone(timezone).toFormat('yyyy-MM-dd HH:mm:ss ZZZZ');
    } catch (error) {
      console.error('Error formatting date', error);
      return dateTimeStr;
    }
  };

  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  // Calculate total postage
  const totalPostage = useMemo(() => {
    return parsedData.reduce((sum, item) => sum + item.postage, 0);
  }, [parsedData]);

  const baseUrl = useMemo(() => `${CORE_BASE_URL}/line_items/report/postage_fee`, []);

  const fileName = useMemo(
    () => `postage_fee_${DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ')}.csv`,
    [timezone]
  );

  const handleTimezoneChange = (newTimezone: string) => {
    setTimezone(newTimezone);
    
    // Update formatted dates when timezone changes
    setParsedData(prevData => 
      prevData.map(item => ({
        ...item,
        formattedDateTime: formatDateTime(item.dateTime, newTimezone)
      }))
    );
  };

  const download = useCallback(() => {
    if (trackingDataRef.current.length === 0) {
      return;
    }
    
    setDownloadDisabled(true);
    setDownloadLoading(true);
    
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tracking_data: trackingDataRef.current
      })
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error('Download error:', error);
        setDownloadLoading(false);
        notification.open({
          key: "error",
          message: "Download Error",
          description: "Failed to download the report. Please try again.",
          duration: 0,
        });
      });
  }, [baseUrl, fileName]);

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.txt,.csv',
    customRequest: ({ file, onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: onFileChange,
  };

  const columns: TableColumnsType<ParsedRowData> = [
    {
      title: 'Package Identification Code (PIC)',
      dataIndex: 'pic',
      key: 'pic',
      sorter: (a: ParsedRowData, b: ParsedRowData) => 
        (a.pic || '').localeCompare(b.pic || ''),
    },
    {
      title: 'Transaction Date/Time',
      dataIndex: 'formattedDateTime',
      key: 'dateTime',
      sorter: (a: ParsedRowData, b: ParsedRowData) => 
        new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime(),
      defaultSortOrder: 'descend' as SortOrder,
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      sorter: (a: ParsedRowData, b: ParsedRowData) => 
        ((a.transactionType || '') > (b.transactionType || '') ? 1 : -1),
    },
    {
      title: 'Postage ($)',
      dataIndex: 'postage',
      key: 'postage',
      render: (value: number) => value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      sorter: (a: ParsedRowData, b: ParsedRowData) => a.postage - b.postage
    },
    {
      title: 'External ID',
      dataIndex: 'externalId',
      key: 'externalId',
      render: (value: string | undefined) => value || '-',
      sorter: (a: ParsedRowData, b: ParsedRowData) => 
        (a.externalId || '').localeCompare(b.externalId || ''),
    }
  ];

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Postage Fee Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 20 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={handleTimezoneChange}
          />
        </Form.Item>
        <Form.Item
          label="Upload File"
          extra="Upload a CSV file containing Package Identification Codes (PIC), Transaction Date/Time, Postage values, and Transaction Type. The Cust Ref Num2 column will be used as External ID if present. Both comma and tab-delimited files are supported."
          style={{ marginBottom: 20 }}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        
        {isProcessing ? (
          <Spin tip="Processing file..." />
        ) : (
          <>
            {error ? (
              <Alert
                message="Error Processing File"
                description={
                  <div>
                    <p>{error}</p>
                    <p>Your file must contain the following columns:</p>
                    <ul>
                      <li><strong>Package Identification Code (PIC)</strong>: The tracking code</li>
                      <li><strong>Transaction Date/Time</strong>: When the tracking code was created</li>
                      <li><strong>Postage</strong>: The postage amount</li>
                    </ul>
                    <p>The following columns are optional but will be included if present:</p>
                    <ul>
                      <li><strong>Transaction Type</strong>: The type of transaction</li>
                      <li><strong>Cust Ref Num2</strong>: Will be used as the External ID</li>
                    </ul>
                    <p>Please check your file format and try again.</p>
                  </div>
                }
                type="error"
                showIcon
                style={{ marginBottom: 20 }}
              />
            ) : parsedData.length > 0 ? (
              <div style={{ marginBottom: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                  <Text>
                    {parsedData.length} tracking codes found in file
                  </Text>
                  <Text strong>
                    Total Postage: ${totalPostage.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Text>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <Table 
                    columns={columns} 
                    dataSource={parsedData} 
                    size="small"
                    pagination={{
                      pageSize: 50,
                      showSizeChanger: false,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                    }}
                    scroll={{ y: 400 }}
                    sticky
                  />
                </div>
              </div>
            ) : fileUploaded ? (
              <Empty description="No valid tracking codes found in the file" />
            ) : null}
            
            <Form.Item>
              <Button 
                type="primary" 
                onClick={download}
                disabled={parsedData.length === 0}
              >
                Download Report
              </Button>
            </Form.Item>
            
            {isDownloadLoading && (
              <Spin tip="Preparing Report..." size="large">
                <div className="content" />
              </Spin>
            )}
          </>
        )}
      </Form>
    </ErrorBoundary>
  );
};
