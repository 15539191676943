import React, { ChangeEvent, useCallback, useMemo, useState, useRef } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Button, Form, Input, Select, Spin, Typography, notification, Upload } from "antd";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";
import { UploadOutlined } from '@ant-design/icons';

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

const { TextArea } = Input;
const { Text } = Typography;

// Regex for cleaning input - compile once outside component
const REGEX_SPACE = /[\s,;'"]+/g;

export const CustomsReportPage = () => {
  const [input, setInput] = useState('');
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [uniqueCount, setUniqueCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Store unique values in ref to avoid re-renders
  const uniqueValuesRef = useRef(new Set<string>());

  const processText = useCallback((text: string) => {
    const spaceFixedInput = text.replace(REGEX_SPACE, ' ');
    const values = spaceFixedInput.split(' ');
    const uniqueValues = new Set(values.filter(id => id.length > 0));
    uniqueValuesRef.current = uniqueValues;
    setUniqueCount(uniqueValues.size);
  }, []);

  // Process any input value changes, whether from typing or programmatic updates
  const processValue = useCallback((value: string) => {
    setInput(value);
    processText(value);
  }, [processText]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    processValue(e.target.value);
  };

  const onFileChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsProcessing(true);
      return;
    }
    
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        processValue(text);
        setIsProcessing(false);
      };
      reader.readAsText(info.file.originFileObj);
    }
  };

  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  const baseUrl = useMemo(() => `${CORE_BASE_URL}/line_items/report/customs`, []);

  const fileName = useMemo(
    () => `customs_${DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ')}.csv`,
    [timezone]
  );

  const download = useCallback(() => {
    setDownloadDisabled(true);
    setDownloadLoading(true);
    
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tracking_codes: Array.from(uniqueValuesRef.current)
      })
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        notification.open({
          key: "error",
          message: "Download Error",
          description: "Failed to download the report. Please try again.",
          duration: 0,
        });
      });
  }, [baseUrl, fileName]);

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.txt,.csv',
    customRequest: ({ file, onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: onFileChange,
  };

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Customs Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={setTimezone}
          />
        </Form.Item>
        <Form.Item
          label="Upload File"
          extra="Upload a CSV or text file containing identifiers (one per line or comma separated)"
          style={{ marginBottom: 10 }}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Or Enter Identifiers Manually"
          extra="Generate a report for all included tracking numbers and order product ids. Identifiers may be line or comma separated."
          style={{ marginBottom: 10 }}
        >
          <TextArea 
            value={input} 
            onChange={onChange} 
            rows={6}
            disabled={isProcessing}
          />
          <Text type="secondary">
            {isProcessing ? 'Processing...' : 
              `${uniqueCount} Unique Identifier${uniqueCount === 1 ? '' : 's'}`}
          </Text>
        </Form.Item>
        <Form.Item>
          <Button 
            type="primary" 
            onClick={download}
            disabled={isProcessing || uniqueCount === 0}
          >
            Download
          </Button>
        </Form.Item>
        {isDownloadLoading && (
          <Spin tip="Preparing Report..." size="large">
            <div className="content" />
          </Spin>
        )}
      </Form>
    </ErrorBoundary>
  );
};
