import React, { ChangeEvent, useCallback, useMemo, useState, useRef } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Button, Form, Input, Select, Spin, Typography, notification, Upload } from "antd";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";
import { UploadOutlined, ReloadOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

const { TextArea } = Input;
const { Text } = Typography;

// Regex for cleaning input - compile once outside component
const REGEX_SPACE = /[\s,;'"]+/g;

export const PackingSlipReportPage = () => {
  const [input, setInput] = useState('');
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [uniqueCount, setUniqueCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Calculate custom Excel-like date number for current date in the selected timezone
  // Calibrated to match: March 5, 2025 = 45721
  const getExcelDateNumber = useCallback((timezone: string) => {
    // Get current date in the selected timezone
    const now = DateTime.now().setZone(timezone);
    
    // Use calibrated epoch that gives March 5, 2025 = 45721
    const calibratedEpoch = DateTime.fromISO('1899-12-30');
    
    // Calculate days difference and round to integer
    const diffDays = Math.round(now.diff(calibratedEpoch, 'days').days);
    
    return diffDays;
  }, []);
  
  // Generate default PO number based on current date in selected timezone
  const generateDefaultPoNumber = useCallback((timezone: string) => {
    const excelDateNumber = getExcelDateNumber(timezone);
    return `DTG-PL-${excelDateNumber}`;
  }, [getExcelDateNumber]);
  
  // Set initial PO number value
  const [poNumber, setPoNumber] = useState(() => generateDefaultPoNumber(timezone));
  
  // Helper functions to manage the PO number suffix
  const parsePoNumber = useCallback((value: string) => {
    // Look for a trailing -# or -## pattern (where # is a digit)
    // But ensure we're not matching the Excel date portion (which would be much larger)
    const match = value.match(/^(.+?)(?:-(\d{1,2}))?$/);
    if (!match) return { base: value, suffix: null };
    
    const potentialSuffix = match[2] ? parseInt(match[2], 10) : null;
    
    // If the potential suffix is a large number (e.g. part of an Excel date like 45722),
    // then treat the whole string as the base with no suffix
    if (potentialSuffix !== null && potentialSuffix > 99) {
      return { base: value, suffix: null };
    }
    
    return {
      base: match[1],
      suffix: potentialSuffix
    };
  }, []);
  
  // Maximum suffix value
  const MAX_SUFFIX = 99;
  
  // Check if the current PO number is the default
  const isDefaultPoNumber = useCallback(() => {
    return poNumber === generateDefaultPoNumber(timezone);
  }, [poNumber, generateDefaultPoNumber, timezone]);
  
  // Check if we can decrement (has a suffix greater than 2)
  const canDecrement = useCallback(() => {
    const { suffix } = parsePoNumber(poNumber);
    return suffix !== null && suffix >= 2;
  }, [poNumber, parsePoNumber]);
  
  // Check if we can increment (has no suffix or suffix less than MAX_SUFFIX)
  const canIncrement = useCallback(() => {
    const { suffix } = parsePoNumber(poNumber);
    return suffix === null || (suffix !== null && suffix < MAX_SUFFIX);
  }, [poNumber, parsePoNumber]);
  
  const incrementSuffix = useCallback(() => {
    if (!canIncrement()) return;
    
    const { base, suffix } = parsePoNumber(poNumber);
    
    if (suffix === null) {
      // No suffix, add -2
      setPoNumber(`${base}-2`);
    } else if (suffix < MAX_SUFFIX) {
      // Increment existing suffix
      setPoNumber(`${base}-${suffix + 1}`);
    }
  }, [poNumber, parsePoNumber, canIncrement]);
  
  const decrementSuffix = useCallback(() => {
    if (!canDecrement()) return;
    
    const { base, suffix } = parsePoNumber(poNumber);
    
    // At this point, we know suffix is not null and >= 2 because of canDecrement()
    // But we need to use a non-null assertion for TypeScript
    if (suffix === 2) {
      // Remove suffix completely when going from 2 to 1
      setPoNumber(base);
    } else if (suffix && suffix > 2) {
      // Decrement existing suffix
      setPoNumber(`${base}-${suffix - 1}`);
    }
  }, [poNumber, parsePoNumber, canDecrement]);
  
  // Store unique values in ref to avoid re-renders
  const uniqueValuesRef = useRef(new Set<string>());

  const processText = useCallback((text: string) => {
    const spaceFixedInput = text.replace(REGEX_SPACE, ' ');
    const values = spaceFixedInput.split(' ');
    const uniqueValues = new Set(values.filter(id => id.length > 0));
    uniqueValuesRef.current = uniqueValues;
    setUniqueCount(uniqueValues.size);
  }, []);

  // Process any input value changes, whether from typing or programmatic updates
  const processValue = useCallback((value: string) => {
    setInput(value);
    processText(value);
  }, [processText]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    processValue(e.target.value);
  };

  const onPoNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPoNumber(e.target.value);
  };
  
  // Update PO number when timezone changes
  const onTimezoneChange = (value: string) => {
    setTimezone(value);
    setPoNumber(generateDefaultPoNumber(value));
  };

  const onFileChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsProcessing(true);
      return;
    }
    
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        processValue(text);
        setIsProcessing(false);
      };
      reader.readAsText(info.file.originFileObj);
    }
  };

  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  const baseUrl = useMemo(() => `${CORE_BASE_URL}/line_items/report/packing_slip`, []);

  const fileName = useMemo(
    () => {
      const timeString = DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ');
      const trimmedPoNumber = poNumber.trim();
      
      if (trimmedPoNumber) {
        // Remove any invalid filename characters and replace spaces with underscores
        const safePoNumber = trimmedPoNumber.replace(/[\\/:*?"<>|]/g, '').replace(/\s+/g, '_');
        return `packing_slip_${safePoNumber}_${timeString}.csv`;
      }
      
      return `packing_slip_${timeString}.csv`;
    },
    [timezone, poNumber]
  );

  const download = useCallback(() => {
    setDownloadDisabled(true);
    setDownloadLoading(true);
    
    const requestBody: any = {
      tracking_codes: Array.from(uniqueValuesRef.current)
    };
    
    // Only include po_num if it has a value
    if (poNumber.trim()) {
      requestBody.po_num = poNumber.trim();
    }
    
    fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody)
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        notification.open({
          key: "error",
          message: "Download Error",
          description: "Failed to download the report. Please try again.",
          duration: 0,
        });
      });
  }, [baseUrl, fileName, poNumber]);

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.txt,.csv',
    customRequest: ({ file, onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: onFileChange,
  };

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Packing Slip Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={onTimezoneChange}
          />
        </Form.Item>
        <Form.Item
          label="Purchase Order Number"
          extra="Enter a purchase order number to include with the report. Use arrows to add/adjust a version suffix or the reset icon to restore today's default value."
          style={{ marginBottom: 10 }}
        >
          <Input 
            value={poNumber} 
            onChange={onPoNumberChange} 
            placeholder="Purchase Order Number"
            style={{ width: 300 }}
            addonAfter={
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                height: '30px', // Fixed height to match input
                padding: '0 4px' // Reduce padding
              }}>
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  height: '100%',
                  marginTop: '-1px' // Fine-tune vertical position
                }}>
                  <Button 
                    type="text"
                    style={{ 
                      padding: 0, 
                      minWidth: '20px', // Slightly smaller
                      height: '14px', 
                      lineHeight: '1',
                      marginBottom: '-1px', // Adjust spacing between buttons
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClick={incrementSuffix}
                    disabled={!canIncrement()}
                    icon={<UpOutlined style={{ fontSize: '9px' }} />}
                  />
                  <Button 
                    type="text"
                    style={{ 
                      padding: 0, 
                      minWidth: '20px', // Slightly smaller
                      height: '14px', 
                      lineHeight: '1',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClick={decrementSuffix}
                    disabled={!canDecrement()}
                    icon={<DownOutlined style={{ fontSize: '9px' }} />}
                  />
                </div>
                <Button 
                  type="text" 
                  size="small" 
                  onClick={() => setPoNumber(generateDefaultPoNumber(timezone))}
                  title="Reset to default value"
                  style={{ marginLeft: '4px', padding: '0 4px' }}
                  disabled={isDefaultPoNumber()}
                  icon={<ReloadOutlined style={{ fontSize: '12px' }} />}
                />
              </div>
            }
          />
        </Form.Item>
        <Form.Item
          label="Upload File"
          extra="Upload a CSV or text file containing identifiers (one per line or comma separated)"
          style={{ marginBottom: 10 }}
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Or Enter Identifiers Manually"
          extra="Generate a report for all included tracking numbers and order product ids. Identifiers may be line or comma separated."
          style={{ marginBottom: 10 }}
        >
          <TextArea 
            value={input} 
            onChange={onChange} 
            rows={6}
            disabled={isProcessing}
          />
          <Text type="secondary">
            {isProcessing ? 'Processing...' : 
              `${uniqueCount} Unique Identifier${uniqueCount === 1 ? '' : 's'}`}
          </Text>
        </Form.Item>
        <Form.Item>
          <Button 
            type="primary" 
            onClick={download}
            disabled={isProcessing || uniqueCount === 0}
          >
            Download
          </Button>
        </Form.Item>
        {isDownloadLoading && (
          <Spin tip="Preparing Report..." size="large">
            <div className="content" />
          </Spin>
        )}
      </Form>
    </ErrorBoundary>
  );
};
