import React, { ChangeEvent, useCallback, useState, useRef } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Button, Form, Input, Table, Typography, Upload, notification, Spin, Tooltip, Collapse } from 'antd';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CORE_BASE_URL } from "../../../api/core/common.js";

const { TextArea } = Input;
const { Text, Title } = Typography;
const { Panel } = Collapse;

// Constants for ID patterns and cleaning
const BATCH_SIZE = 100;
const ZIFT_ID_PATTERN = /^O-/;
const SALES_ORDER_PATTERN = /^SO-/;
const CLEAN_ID_PATTERN = /[^a-zA-Z0-9-]/g;

// Map of D365 statuses to corresponding valid ZIFT statuses
const D365_TO_ZIFT_STATUS_MAP: Record<string, string[]> = {
  'active': ['active', 'pending'],
  'cancelled': ['canceled'],
  'complete': ['complete']
};

// Order data representation
interface OrderData {
  ziftId: string | null;
  externalId: string | null;
  salesOrderNumber: string | null;
  ziftStatus: string | null;
  d365Status: string | null;
  isInvalid?: boolean;
  syncStatus?: 'processing' | 'success' | 'error';
  syncMessage?: string;
  syncActions?: string[];
}

// API Response types
interface ExternalOrderResponse {
  id: string;
  zift_id: string;
  status: string;
  external_id: string;
}

interface D365OrderResponse {
  SalesOrderNumber: string;
  CustomerRequisitionNumber: string;
  SalesOrderProcessingStatus: string;
  GEI_Turn_Hdr1: string;
}

interface SyncResponse {
  id: string;
  status: string;
  message: string;
  actions: string[];
}

// Grouped orders for display
interface GroupedOrders {
  notSynced: OrderData[];
  nonSyncing: OrderData[];
  synced: OrderData[];
  notFound: OrderData[];
}

export const SyncPage = () => {
  // State
  const [input, setInput] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [syncResults, setSyncResults] = useState<Map<string, SyncResponse>>(new Map());
  const [hasSynced, setHasSynced] = useState(false);
  const [progress, setProgress] = useState(0);

  // Refs for storing IDs
  const externalIdsRef = useRef<string[]>([]);
  const ziftIdsRef = useRef<string[]>([]);
  const salesOrderNumbersRef = useRef<string[]>([]);

  const processText = useCallback((text: string) => {
    const ids = text.split(/[\s,;]+/)
      .filter(id => id.length > 0)
      .map(id => id.replace(CLEAN_ID_PATTERN, ''));
    
    // Reset refs with deduplicated arrays
    externalIdsRef.current = Array.from(new Set(ids.filter(id => !ZIFT_ID_PATTERN.test(id) && !SALES_ORDER_PATTERN.test(id))));
    ziftIdsRef.current = Array.from(new Set(ids.filter(id => ZIFT_ID_PATTERN.test(id))));
    salesOrderNumbersRef.current = Array.from(new Set(ids.filter(id => SALES_ORDER_PATTERN.test(id))));

    // Initialize order data with known information and handle duplicates
    const initialDataMap = new Map<string, OrderData>();

    // Process ZIFT IDs first since they're our primary key
    ziftIdsRef.current.forEach(id => {
      initialDataMap.set(id, {
        ziftId: id,
        externalId: null,
        salesOrderNumber: null,
        ziftStatus: null,
        d365Status: null
      });
    });

    // Add external IDs
    externalIdsRef.current.forEach(id => {
      const existingZiftId = Array.from(initialDataMap.values())
        .find(data => data.externalId === id)?.ziftId;
      
      if (!existingZiftId) {
        initialDataMap.set(`external-${id}`, {
          ziftId: null,
          externalId: id,
          salesOrderNumber: null,
          ziftStatus: null,
          d365Status: null
        });
      }
    });

    // Add sales order numbers
    salesOrderNumbersRef.current.forEach(id => {
      const existingZiftId = Array.from(initialDataMap.values())
        .find(data => data.salesOrderNumber === id)?.ziftId;
      
      if (!existingZiftId) {
        initialDataMap.set(`so-${id}`, {
          ziftId: null,
          externalId: null,
          salesOrderNumber: id,
          ziftStatus: null,
          d365Status: null
        });
      }
    });

    const initialData = Array.from(initialDataMap.values());
    setOrderData(initialData);
    setIsDataLoaded(false);
    setHasSynced(false);
    setSyncResults(new Map());
  }, []);

  const fetchExternalOrders = async (ids: string[]): Promise<ExternalOrderResponse[]> => {
    if (ids.length === 0) return [];
    const response = await fetch(`${CORE_BASE_URL}/orders/find_external`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: ids,
        select: ["id", "zift_id", "status", "external_id"]
      })
    });
    return response.json();
  };

  const fetchZiftOrders = async (ids: string[]): Promise<ExternalOrderResponse[]> => {
    if (ids.length === 0) return [];
    const response = await fetch(`${CORE_BASE_URL}/orders/find_zift`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: ids,
        select: ["id", "zift_id", "status", "external_id"]
      })
    });
    return response.json();
  };

  const fetchSalesOrders = async (numbers: string[]): Promise<D365OrderResponse[]> => {
    if (numbers.length === 0) return [];
    const response = await fetch(`${CORE_BASE_URL}/d365/sales_order_headers_by_number`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sales_order_number: numbers,
        select: ["SalesOrderNumber", "CustomerRequisitionNumber", "SalesOrderProcessingStatus", "GEI_Turn_Hdr1"]
      })
    });
    return response.json();
  };

  const fetchD365ByRequisition = async (requisitionNumbers: string[]): Promise<D365OrderResponse[]> => {
    if (requisitionNumbers.length === 0) return [];
    const response = await fetch(`${CORE_BASE_URL}/d365/sales_order_headers_by_requisition`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        customer_requisition_number: requisitionNumbers,
        select: ["SalesOrderNumber", "CustomerRequisitionNumber", "SalesOrderProcessingStatus", "GEI_Turn_Hdr1"]
      })
    });
    return response.json();
  };

  // Function to get D365 status and matching ZIFT statuses
  const getD365StatusMapping = (order: D365OrderResponse): string[] => {
    if (order.SalesOrderProcessingStatus === "Canceled") {
      return D365_TO_ZIFT_STATUS_MAP['cancelled'];
    }
    if (order.SalesOrderProcessingStatus === "Invoiced") {
      return D365_TO_ZIFT_STATUS_MAP['complete'];
    }
    if (order.GEI_Turn_Hdr1 === "Reported as finished") {
      return D365_TO_ZIFT_STATUS_MAP['complete'];
    }
    return D365_TO_ZIFT_STATUS_MAP['active'];
  };

  // Function to get primary D365 status (for display purposes)
  const getD365PrimaryStatus = (order: D365OrderResponse): string => {
    if (order.SalesOrderProcessingStatus === "Canceled") {
      return 'cancelled';
    }
    if (order.SalesOrderProcessingStatus === "Invoiced") {
      return 'complete';
    }
    if (order.GEI_Turn_Hdr1 === "Reported as finished") {
      return 'complete';
    }
    return 'active';
  };

  const sortOrders = (orders: OrderData[]): OrderData[] => {
    return orders.sort((a, b) => {
      if (a.ziftId && b.ziftId) return a.ziftId.localeCompare(b.ziftId);
      if (a.ziftId) return -1;
      if (b.ziftId) return 1;
      if (a.externalId && b.externalId) return a.externalId.localeCompare(b.externalId);
      if (a.externalId) return -1;
      if (b.externalId) return 1;
      if (a.salesOrderNumber && b.salesOrderNumber) return a.salesOrderNumber.localeCompare(b.salesOrderNumber);
      return 0;
    });
  };

  // Updated groupOrders function using the mapping
  const groupOrders = (orders: OrderData[]): GroupedOrders => {
    const grouped: GroupedOrders = {
      notSynced: [],
      nonSyncing: [],
      synced: [],
      notFound: []
    };

    orders.forEach(order => {
      if (order.isInvalid || !order.ziftStatus) {
        grouped.notFound.push(order);
      } else if (order.ziftStatus === 'new' || order.ziftStatus === 'invalid' || order.ziftStatus === 'deleted') {
        grouped.nonSyncing.push(order);
      } else if (!order.d365Status) {
        // Canceled orders that are not already synced to D365 do not need to be synced
        if (order.ziftStatus === 'canceled') {
          grouped.nonSyncing.push(order);
        } else {
          grouped.notSynced.push(order); 
        }
      } else {
        // Get valid ZIFT statuses for this D365 status
        const validZiftStatuses = D365_TO_ZIFT_STATUS_MAP[order.d365Status] || [order.d365Status];
        
        // Check if current ZIFT status is in the set of valid statuses
        if (validZiftStatuses.includes(order.ziftStatus)) {
          grouped.synced.push(order);
        } else {
          grouped.notSynced.push(order);
        }
      }
    });

    // Sort each group
    grouped.notSynced = sortOrders(grouped.notSynced);
    grouped.nonSyncing = sortOrders(grouped.nonSyncing);
    grouped.synced = sortOrders(grouped.synced);
    grouped.notFound = sortOrders(grouped.notFound);

    return grouped;
  };

  // Function to process arrays in batches
  const processInBatches = <T,>(items: T[]): T[][] => {
    const batches: T[][] = [];
    for (let i = 0; i < items.length; i += BATCH_SIZE) {
      batches.push(items.slice(i, i + BATCH_SIZE));
    }
    return batches;
  };

  const processBatchedRequests = async <T extends ExternalOrderResponse | D365OrderResponse>(
    items: string[],
    batchSize: number,
    processFn: (batch: string[]) => Promise<T[]>,
    updateProgress: (completed: number, total: number) => void
  ): Promise<T[]> => {
    if (items.length === 0) return [];
    
    const batches: string[][] = [];
    for (let i = 0; i < items.length; i += batchSize) {
      batches.push(items.slice(i, i + batchSize));
    }
  
    const results: T[] = [];
    for (let i = 0; i < batches.length; i++) {
      const batchResults = await processFn(batches[i]);
      results.push(...batchResults);
      updateProgress(i + 1, batches.length);
    }
  
    return results;
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    setSyncResults(new Map());
    setHasSynced(false);
    setProgress(0);
    
    try {
      // Calculate total number of API calls we'll make
      const externalBatches = Math.ceil(externalIdsRef.current.length / BATCH_SIZE);
      const ziftBatches = Math.ceil(ziftIdsRef.current.length / BATCH_SIZE);
      const salesOrderBatches = Math.ceil(salesOrderNumbersRef.current.length / BATCH_SIZE);
      
      const totalBatches = externalBatches + ziftBatches + salesOrderBatches;
      let completedBatches = 0;
  
      const updateProgress = (completed: number, total: number) => {
        completedBatches++;
        setProgress(Math.min(Math.round((completedBatches / (totalBatches * 2)) * 100), 100));
      };
  
      // Process all requests in batches
      const [externalResults, ziftResults, salesOrderResults] = await Promise.all([
        processBatchedRequests(
          externalIdsRef.current,
          BATCH_SIZE,
          (batch) => fetchExternalOrders(batch),
          updateProgress
        ),
        processBatchedRequests(
          ziftIdsRef.current,
          BATCH_SIZE,
          (batch) => fetchZiftOrders(batch),
          updateProgress
        ),
        processBatchedRequests(
          salesOrderNumbersRef.current,
          BATCH_SIZE,
          (batch) => fetchSalesOrders(batch),
          updateProgress
        )
      ]);
  
      // Collect ZIFT IDs for D365 lookup
      const ziftIdsForD365 = [
        ...externalResults.map(r => r.zift_id),
        ...ziftResults.map(r => r.zift_id)
      ];
  
      // Collect ZIFT IDs from sales orders for ZIFT data
      const ziftIdsFromSO = salesOrderResults
        .map(r => r.CustomerRequisitionNumber)
        .filter(id => id);
  
      // Additional data fetching with batching
      const [d365Results, additionalZiftResults] = await Promise.all([
        processBatchedRequests(
          ziftIdsForD365,
          BATCH_SIZE,
          (batch) => fetchD365ByRequisition(batch),
          updateProgress
        ),
        processBatchedRequests(
          ziftIdsFromSO,
          BATCH_SIZE,
          (batch) => fetchZiftOrders(batch),
          updateProgress
        )
      ]);
  
      // Update order data with all results
      setOrderData(prev => {
        const dataMap = new Map<string, OrderData>();
        
        // Initialize map with existing data
        prev.forEach(entry => {
          if (entry.ziftId) {
            dataMap.set(entry.ziftId, entry);
          } else if (entry.externalId) {
            dataMap.set(`external-${entry.externalId}`, entry);
          } else if (entry.salesOrderNumber) {
            dataMap.set(`so-${entry.salesOrderNumber}`, entry);
          }
        });
  
        const processedIds = new Set<string>();
  
        // Process external results
        externalResults.forEach(result => {
          const ziftId = result.zift_id;
          const externalId = result.external_id;
          const existingData = dataMap.get(ziftId) || {
            ziftId: ziftId,
            externalId: null,
            salesOrderNumber: null,
            ziftStatus: null,
            d365Status: null
          };
  
          dataMap.set(ziftId, {
            ...existingData,
            externalId: externalId,
            ziftStatus: result.status
          });
          processedIds.add(externalId);
          
          // Remove the external ID entry if it exists
          dataMap.delete(`external-${externalId}`);
        });
  
        // Process ZIFT results
        [...ziftResults, ...additionalZiftResults].forEach(result => {
          const ziftId = result.zift_id;
          const existingData = dataMap.get(ziftId) || {
            ziftId: ziftId,
            externalId: null,
            salesOrderNumber: null,
            ziftStatus: null,
            d365Status: null
          };

          dataMap.set(ziftId, {
            ...existingData,
            externalId: result.external_id,
            ziftStatus: result.status
          });
          processedIds.add(ziftId);
        });
  
        // Process D365 results
        [...salesOrderResults, ...d365Results].forEach(result => {
          const ziftId = result.CustomerRequisitionNumber;
          const d365Id = result.SalesOrderNumber;
          const existingData = dataMap.get(ziftId) || {
            ziftId: ziftId,
            externalId: null,
            salesOrderNumber: null,
            ziftStatus: null,
            d365Status: null
          };
  
          dataMap.set(ziftId, {
            ...existingData,
            salesOrderNumber: d365Id,
            d365Status: getD365PrimaryStatus(result)
          });
          processedIds.add(d365Id);
          
          // Remove the sales order entry if it exists
          dataMap.delete(`so-${d365Id}`);
        });
  
        // Mark remaining entries as invalid
        Array.from(dataMap.values()).forEach(entry => {
          const id = entry.ziftId || entry.externalId || entry.salesOrderNumber;
          if (id && !processedIds.has(id)) {
            entry.isInvalid = true;
          }
        });
  
        return Array.from(dataMap.values());
      });
  
      setIsDataLoaded(true);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to fetch order data. Please try again.",
        duration: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSync = async () => {
    setIsSyncing(true);
    setProgress(0);
    const grouped = groupOrders(orderData);
    const rowsToSync = grouped.notSynced.filter(row => row.ziftId);
    
    // Process in chunks of 10
    const batches = processInBatches(rowsToSync);

    try {
      for (let i = 0; i < batches.length; i++) {
        const chunk = batches[i];
        // Mark chunk as processing
        setOrderData(prev => prev.map(row => ({
          ...row,
          syncStatus: chunk.some(c => c.ziftId === row.ziftId) ? 'processing' : row.syncStatus
        })));

        // Process chunk in parallel
        const results = await Promise.allSettled(
          chunk.map(row => 
            fetch(`${CORE_BASE_URL}/zift/d365/${row.ziftId}`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' }
            })
            .then(async response => {
              const data = await response.json();
              if (!response.ok || data.status === 'error') {
                throw new Error(data.message || 'Unknown Error');
              }
              return data;
            })
          )
        );

        // Update results
        setOrderData(prev => prev.map(row => {
          const resultIndex = chunk.findIndex(c => c.ziftId === row.ziftId);
          if (resultIndex === -1) return row;

          const result = results[resultIndex];
          if (result.status === 'fulfilled') {
            setSyncResults(prev => new Map(prev).set(row.ziftId!, result.value));
            return {
              ...row,
              syncStatus: 'success',
              syncMessage: result.value.message,
              syncActions: result.value.actions
            };
          } else {
            setSyncResults(prev => new Map(prev).set(row.ziftId!, {
              id: row.ziftId!,
              status: 'error',
              message: result.reason.message || 'Unknown Error',
              actions: []
            }));
            return {
              ...row,
              syncStatus: 'error',
              syncMessage: result.reason.message || 'Unknown Error'
            };
          }
        }));

        setProgress(Math.round(((i + 1) / batches.length) * 100));
      }
    } catch (error) {
      notification.error({
        message: "Sync Error",
        description: "Failed to sync some orders. Please try again.",
        duration: 0,
      });
    } finally {
      setIsSyncing(false);
      setHasSynced(true);
    }
  };

  const handleDownloadReport = () => {
    const headers = ['ZIFT ID', 'Status', 'Message', 'Actions'];
    
    const rows = Array.from(syncResults.entries())
      .sort(([idA], [idB]) => idA.localeCompare(idB))
      .map(([id, result]) => [
        id,
        result.status,
        `"${(result.message || '').replace(/"/g, '""')}"`,
        `"${(result.actions || []).join(', ').replace(/"/g, '""')}"`
      ]);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sync_report.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    processText(e.target.value);
  };

  const onFileChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsProcessing(true);
      return;
    }
    
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        setInput(text);
        processText(text);
        setIsProcessing(false);
      };
      reader.readAsText(info.file.originFileObj);
    }
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.txt,.csv',
    customRequest: ({ file, onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: onFileChange,
  };

  const initialColumns = [
    {
      title: 'ZIFT ID',
      dataIndex: 'ziftId',
      key: 'ziftId'
    },
    {
      title: 'External ID',
      dataIndex: 'externalId',
      key: 'externalId'
    },
    {
      title: 'Sales Order Number',
      dataIndex: 'salesOrderNumber',
      key: 'salesOrderNumber'
    }
  ];

  const fullColumns = [
    ...initialColumns,
    {
      title: 'ZIFT Status',
      dataIndex: 'ziftStatus',
      key: 'ziftStatus'
    },
    {
      title: 'D365 Status',
      dataIndex: 'd365Status',
      key: 'd365Status',
      render: (text: string | null, record: OrderData) => {
        if (!text) return null;
        
        const validZiftStatuses = D365_TO_ZIFT_STATUS_MAP[text] || [text];
        const tooltipContent = validZiftStatuses.length > 1 
          ? `Matches with: ${validZiftStatuses.join(', ')}`
          : null;
        
        return tooltipContent ? (
          <Tooltip title={tooltipContent}>
            <span>{text} <InfoCircleOutlined style={{ fontSize: '12px' }} /></span>
          </Tooltip>
        ) : text;
      }
    }
  ];

  const syncColumns = [
    ...fullColumns,
    {
      title: 'Sync Status',
      dataIndex: 'syncStatus',
      key: 'syncStatus',
      render: (text: string | null, record: OrderData) => {
        if (record.syncStatus === 'processing') {
          return <Spin size="small" />;
        }
        
        const status = record.syncStatus === 'success' ? 'Success' : 
                      record.syncStatus === 'error' ? 'Failure' : 
                      '-';
                      
        return (
          <div className="flex items-center">
            <span style={{ marginRight: '16px' }}>{status}</span>
            {record.syncMessage && record.syncMessage.trim() && (
              <Tooltip title={record.syncMessage}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      title: 'Sync Actions',
      key: 'syncActions',
      render: (_: any, record: OrderData) => {
        if (!record.syncActions?.length) return '-';
        
        const actionColors = {
          create: 'bg-blue-100 text-blue-800',
          update: 'bg-green-100 text-green-800',
          cancel: 'bg-red-100 text-red-800'
        };
  
        return (
          <div className="flex gap-1">
            {record.syncActions.map((action, index) => (
              <Tooltip key={index} title={action}>
                <span className={`px-2 py-1 rounded-full text-xs ${actionColors[action as keyof typeof actionColors]}`}>
                  {action.charAt(0).toUpperCase()}
                </span>
              </Tooltip>
            ))}
          </div>
        );
      }
    }
  ];

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Spin tip={isLoading ? `Loading data from services (${progress}%)` : ''} spinning={isLoading}>
        <Form style={{ margin: 60 }} layout="vertical">
          <Title>Order Sync</Title>
          
          <Form.Item
            label="Upload File"
            extra="Upload a CSV or text file containing identifiers (one per line or comma separated)"
            style={{ marginBottom: 10 }}
          >
            <Upload {...uploadProps} disabled={isProcessing || isSyncing || isLoading}>
              <Button icon={<UploadOutlined />} disabled={isProcessing || isSyncing || isLoading}>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Or Enter Identifiers Manually"
            extra="Enter ZIFT IDs (O-), Sales Order Numbers (SO-), or External IDs. Identifiers may be line or comma separated."
            style={{ marginBottom: 10 }}
          >
            <TextArea 
              value={input} 
              onChange={onChange} 
              rows={6}
              disabled={isProcessing || isSyncing || isLoading}
            />
            <Text type="secondary">
              {isProcessing ? 'Processing...' : 
                `${orderData.length} Identifier${orderData.length === 1 ? '' : 's'}`}
            </Text>
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              onClick={fetchAllData}
              disabled={isProcessing || orderData.length === 0 || isLoading || isSyncing}
            >
              Load Data
            </Button>
            <Button 
              type="primary"
              onClick={handleSync}
              disabled={!isDataLoaded || isSyncing || hasSynced || groupOrders(orderData).notSynced.length === 0}
              style={{ marginLeft: 8 }}
            >
              Sync Data
            </Button>
            {syncResults.size > 0 && (
              <Button 
                onClick={handleDownloadReport}
                disabled={isSyncing}
                style={{ marginLeft: 8 }}
              >
                Download Report
              </Button>
            )}
          </Form.Item>

          {orderData.length > 0 && (
            isDataLoaded ? (
                <Collapse defaultActiveKey={groupOrders(orderData).notSynced.length > 0 ? ['notSynced'] : []}>
                  {(() => {
                    const grouped = groupOrders(orderData);
                    return (
                      <>
                        {grouped.notSynced.length > 0 && (
                          <Panel header={`Status Mismatch (${grouped.notSynced.length})`} key="notSynced">
                            <Table 
                              dataSource={grouped.notSynced} 
                              columns={hasSynced || isSyncing ? syncColumns : fullColumns}
                              pagination={false}
                              rowKey={(record) => record.ziftId || record.externalId || record.salesOrderNumber || ''}
                            />
                          </Panel>
                        )}
                        {grouped.nonSyncing.length > 0 && (
                          <Panel header={`Non-Syncing Orders (${grouped.nonSyncing.length})`} key="nonSyncing">
                            <Table 
                              dataSource={grouped.nonSyncing} 
                              columns={fullColumns}
                              pagination={false}
                              rowKey={(record) => record.ziftId || record.externalId || record.salesOrderNumber || ''}
                            />
                          </Panel>
                        )}
                        {grouped.synced.length > 0 && (
                          <Panel header={`Synced Orders (${grouped.synced.length})`} key="synced">
                            <Table 
                              dataSource={grouped.synced} 
                              columns={fullColumns}
                              pagination={false}
                              rowKey={(record) => record.ziftId || record.externalId || record.salesOrderNumber || ''}
                            />
                          </Panel>
                        )}
                        {grouped.notFound.length > 0 && (
                          <Panel header={`Not Found (${grouped.notFound.length})`} key="notFound">
                            <Table 
                              dataSource={grouped.notFound} 
                              columns={fullColumns}
                              pagination={false}
                              rowKey={(record) => record.ziftId || record.externalId || record.salesOrderNumber || ''}
                            />
                          </Panel>
                        )}
                      </>
                    );
                  })()}
                </Collapse>
              ) : (
                <Table 
                  dataSource={orderData} 
                  columns={initialColumns}
                  pagination={false}
                  rowKey={(record) => record.ziftId || record.externalId || record.salesOrderNumber || ''}
                />
              )
            )
          }
        </Form>
      </Spin>
    </ErrorBoundary>
  );
};
