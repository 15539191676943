import React, { useCallback, useMemo, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Button, Form, Select, Spin, notification } from "antd";
import { CORE_BASE_URL } from "../../../api/core/common.js";
import { DateTime } from "luxon";
import { DatePicker, LocationFacilitySelect } from "../../../components/index.js";

import _Title from "antd/lib/typography/Title.js";
const Title = _Title as unknown as typeof _Title.default;

export const PrintWaypointReportPage = () => {
  const [startDate, setStartDate] = useState<Date | null>(
    DateTime.now().startOf('day').minus({ weeks: 1 }).toJSDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    DateTime.now().startOf('day').plus({ weeks: 1 }).toJSDate()
  );
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [facilityId, setFacilityId] = useState<string[]>([]);
  const [isDownloadDisabled, setDownloadDisabled] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [hasDownloadFailed, setDownloadFailed] = useState(false);

  // only including the timezones that are relevant to our business, plus UTC
  const timezoneOptions = [
    { value: 'America/Los_Angeles', label: 'Pacific' },
    { value: 'America/Denver', label: 'Mountain' },
    { value: 'America/Chicago', label: 'Central' },
    { value: 'America/New_York', label: 'Eastern' },
    { value: 'UTC', label: 'UTC' }
  ];

  const fileStartDate = useMemo(
    () => DateTime.fromJSDate(startDate!).setZone(timezone, { keepLocalTime: true }).toISO(),
    [startDate, timezone]
  );
  const fileEndDate = useMemo(
    () => DateTime.fromJSDate(endDate!).setZone(timezone, { keepLocalTime: true }).toISO(),
    [endDate, timezone]
  );

  // Build facility parameter if facilities are selected
  const facilityParam = useMemo(() => {
    if (facilityId && facilityId.length > 0) {
      // Use Rails array parameter format: facility[]=uuid&facility[]=uuid
      return facilityId.map(id => `&facility[]=${encodeURIComponent(id)}`).join('');
    }
    return '';
  }, [facilityId]);

  const fileUrl = useMemo(() => 
    `${CORE_BASE_URL}/scans/report/print_waypoints?timezone=${timezone}&start_date=${fileStartDate}&end_date=${fileEndDate}${facilityParam}`, 
    [timezone, fileStartDate, fileEndDate, facilityParam]
  );

  // easier to generate file name here than alter CORS on server and parse filename from content disposition
  const fileName = useMemo(
    () => `print_waypoint_${DateTime.now().setZone(timezone).toFormat('yyyy_LL_dd-HH_mm_ss_ZZZZ')}.csv`,
    [timezone]);

  // shenanigans to make the dowload detectable within the browser
  const download = useCallback(() => {
    setDownloadDisabled(true);
    setDownloadLoading(true);
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
        setDownloadDisabled(false);
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
        setDownloadFailed(true);
        notification.open({
          key: "error",
          message: "Report Error",
          description: "Something went wrong while generating your report. Please try the manual download link.",
          duration: 0,
        });
      });
  }, [fileUrl, fileName]);

  const onClickManualDownload = useCallback(() => {
    setDownloadFailed(false);
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadDisabled(false);
      setDownloadLoading(false);
    }, 5000);
  }, []);

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred...</div>}>
      <Form
        disabled={isDownloadDisabled}
        style={{ margin: 60 }}
        layout="vertical"
      >
        <Title>Print Waypoints Report</Title>
        <Form.Item
          label="Timezone"
          extra="All dates within the report will be displayed in the chosen timezone."
          style={{ marginBottom: 10 }}
        >
          <Select
            style={{ width: 120 }}
            options={timezoneOptions}
            defaultValue={timezoneOptions[0].value}
            onChange={setTimezone}
          />
        </Form.Item>
        <Form.Item
          label="Facilities"
          extra="Filter the report to specific facilities. Leave empty to include all facilities."
          style={{ marginBottom: 10 }}
        >
          <LocationFacilitySelect
            value={facilityId}
            onChange={(selectedFacilityIds: string[]) => {
              setFacilityId(selectedFacilityIds);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Start Date"
          extra="Generate a report between this date and the end date."
          style={{ marginBottom: 10 }}
        >
          <DatePicker value={startDate} onChange={setStartDate} />
        </Form.Item>
        <Form.Item
          label="End Date"
          extra="Generate a report between the start date and this date"
          style={{ marginBottom: 10 }}
        >
          <DatePicker value={endDate} onChange={setEndDate} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={download}>Download</Button>
        </Form.Item>
        { isDownloadLoading &&
          (
            <Spin tip="Preparing Report..." size="large">
              <div className="content" />
            </Spin>
          )
        }
        { hasDownloadFailed &&
          (
            <a href={fileUrl} download onClick={onClickManualDownload}>Manual Download</a>
          )
        }
      </Form>
    </ErrorBoundary>
  );
};
